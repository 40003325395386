import {
  BrowserRouter,
  Navigate,
  Route,
  Routes
} from 'react-router-dom'
import Home from './pages/Home';
import Layout from './pages/Layout';
import Login from './pages/Login';
import NoPage from './pages/NoPage';
import { fakeAuth } from './utils/auth';

// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route {...rest} element={(props) => (
//     fakeAuth.isAuthenticated === true
//       ? <Component {...props} />
//       : <Navigate replace to='/login' />
//   )} />
// )

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;