import { useEffect, useMemo, useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { Link, redirect, useNavigate } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import { useDropzone } from 'react-dropzone'
import { uploadFile } from '@uploadcare/upload-client'
import { saveAs } from 'file-saver'
import 'react-dropdown/style.css';
import "../assets/Home.css";

const Home = () => {
    const [images, setImages] = useState([])
    const [clients, setClients] = useState([])
    const [mode, setMode] = useState('generated')
    const [username, setUsername] = useState()
    const [uploading, setUploading] = useState(false)
    const [dropzone, showDropzone] = useState(false)
    const redirect = useNavigate()
    const {
        inputRef,
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        }
    });

    const gallery = useRef()
    const scrollLeft = () => {
        if (gallery.current) {
            gallery.current.slideLeft()
        }
    }
    const scrollRight = () => {
        if (gallery.current) {
            gallery.current.slideRight()
        }
    }
    useEffect(() => {
        const username = localStorage.getItem('username')
        if(!username)
            redirect('/login')

        setUsername(username)
        const clients = JSON.parse(localStorage.getItem('clients')) ?? []
        if(clients.length)
            setClients(clients)

        const store = JSON.parse(localStorage.getItem('images')) ?? {}
        if(store[username]?.length)
            setImages(store[username] ?? [])

        window.addEventListener('dragenter', (e) => {
            showDropzone(true)
        })
        return () => {}
    }, [])

    useEffect(() => {
        if (!isDragActive) {
            showDropzone(false)
            if(acceptedFiles.length) {
                setUploading(true)
                let count = acceptedFiles.length
                acceptedFiles.map(file => {
                    uploadFile(file, {
                        publicKey: '903e6ffc0a69bd475cda',
                        store: 'auto',
                    }).then(result => {
                        setImages(images => [
                            ...images,
                            {
                                original: result.cdnUrl,
                                generated: result.cdnUrl,
                                thumbnail: result.cdnUrl,
                            }
                        ])
                    }).catch(err => {
                        console.log(err)
                    }).finally(() => {
                        count--
                        if(count==0) {
                            const store = JSON.parse(localStorage.getItem('images')) ?? {}
                            store[username] = images
                            localStorage.setItem('images', JSON.stringify(store))
                            setUploading(false)
                        }
                    })
                }).splice(0)
                // fetch('http://localhost/upload', {
                //     method: 'POST',
                //     body: formData
                // }).then(res => {

                // }).catch(err => console.log(err))
            }
        }
    }, [isDragActive, acceptedFiles])

    return (
        <div className={`main ${uploading ? 'is-uploading' : ''}`}>
            <div className='gallery'>
                <h1>
                    <img src={require('../assets/logo.png')} />
                    Bienvenido
                </h1>
                <ImageGallery 
                    ref={gallery} 
                    items={images} 
                    original={mode} 
                    showFullscreenButton={false} 
                    showPlayButton={false} 
                    showNav={false} 
                    infinite={false} 
                    slideDuration={0} 
                    useBrowserFullscreen={false} 
                    renderItem={(item) => {
                        return <img src={item[mode]} className="image-gallery-image" loading="eager"/>
                    }} 
                    />
                {!dropzone && images.length==0 ? 
                    <div {...getRootProps({ className: 'dropzone-intro' })}>
                        <input {...getInputProps()} />  
                        <p>Drag & drop image files screen, or click to select files</p>
                        <em>(Only *.jpeg and *.png images will be accepted)</em>
                    </div> : ''}
                {images.length ? <button className='btn-scroll-left' onClick={scrollLeft}>&#9664;</button> : ''}
                {images.length ? <button className='btn-scroll-right' onClick={scrollRight}>&#9654;</button> : ''}
            </div>
            <div className='tools'>
                <div className='panel-user'>
                    <Link className='btn-logout' to='/login' onClick={() => localStorage.removeItem('username')}/>
                </div>
                <div className='panel-clients'>
                    <h3>Clientes</h3>
                    <Dropdown options={clients} value={username ?? ''} className='clients' />
                </div>
                <div className='panel-reserve' />
                <div className='panel-tools'>
                    <h3>Herramientas</h3>
                    <div>
                        <button className='btn-save' disabled={images.length==0} onClick={() => {
                            const url = images[gallery.current.getCurrentIndex()].original
                            saveAs(url)
                        }}></button>
                        <button className='btn-delete' disabled={images.length==0} onClick={() => {
                            images.splice(gallery.current.getCurrentIndex(),1)
                            setImages([...images])
                            gallery.current.slideLeft()
                        }}></button>
                        <button className='btn-upload' {...getRootProps({})}>
                            <input {...getInputProps()} />
                        </button>
                    </div>
                </div>
                <div className='panel-display'>
                    <h3>Modo de visualización</h3>
                    <div>
                        <button className={`btn-origin ${mode == 'original' ? 'selected' : ''}`} onClick={() => setMode('original')}>Mostrar originales</button>
                        <button className={`btn-generated ${mode == 'generated' ? 'selected' : ''}`} onClick={() => setMode('generated')}>Mostrar generadas</button>
                    </div>
                </div>
                {
                    dropzone ?
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <p>Drag & drop image files here, or click to select files</p>
                            <em>(Only *.jpeg and *.png images will be accepted)</em>
                        </div> :
                        ''
                }
            </div>
        </div>
    );
};

export default Home;