import { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import "../assets/Login.css"

const Login = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const redirect = useNavigate()

    const usernameRef = useRef()
    const passwordRef = useRef()

    const onLogin = () => {
        if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(username)) {
            setUsername('')
            setPassword('')
            usernameRef.current?.focus()
        } else if(password=='') {
            passwordRef.current?.focus()
        } else {
            localStorage.setItem('username', username)
            const clients = JSON.parse(localStorage.getItem('clients')) || []
            if(!clients.includes(username)) {
                clients.push(username)
                localStorage.setItem('clients', JSON.stringify(clients))
            }
            redirect('/')
        }
    }

    const onEnter = (e) => {
        if(e.keyCode==13)
            onLogin()
    }

    return (
        <div className="panel-login">
            <h1>Login</h1>
            <hr/>
            <input ref={usernameRef} type="text" value={username} placeholder="Username" onChange={(e) => setUsername(e.currentTarget.value)} onKeyUp={onEnter}/>
            <input ref={passwordRef} type="password" value={password} placeholder="Password" onChange={(e) => setPassword(e.currentTarget.value)} onKeyUp={onEnter}/>
            <a href="">Forgot Password?</a>
            <button onClick={onLogin}>Login</button>
        </div>
    )
}
  
export default Login